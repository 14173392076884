// var vlocal = {
//   apiBackend          : '',
//   dataMaster          : /*'http://'+ window.location.hostname + ':8292', //*/'https://hris.bottis.co.id/dataMaster',
//   dataMasterNew       : /*'http://'+ window.location.hostname + ':8292', //*/'https://hris.bottis.co.id/dataMaster',

//   report              : /*'http://'+ window.location.hostname + ':8797', //*/'https://hris.bottis.co.id/authInfo',
//   smartReport         : /*'http://'+ window.location.hostname + ':8797', //*/'https://hris.bottis.co.id/smartReport',
//   authLogin           : /*'http://'+ window.location.hostname + ':8797', //*/'https://hris.bottis.co.id/authInfo',

//   dataHr1Mod1         : /*'http://'+ window.location.hostname + ':8393', //*/'https://hris.bottis.co.id/dataHr1Mod1',
//   dataHr1Mod2         : /*'http://'+ window.location.hostname + ':8393', //*/'https://hris.bottis.co.id/dataHr1Mod1',
//   dataHr2Mod1         : /*'http://'+ window.location.hostname + ':8393', //*/'https://hris.bottis.co.id/dataHr1Mod1',
//   dataHr2Mod2         : /*'http://'+ window.location.hostname + ':8393', //*/'https://hris.bottis.co.id/dataHr1Mod1',

//   dataHr2Mod3         : 'http://'+ window.location.hostname + ':9696', //*/'https://hris.bottis.co.id/dataHr2Mod3',
//   dataBSC             : /*'http://192.168.0.143:9898', //*/'https://hris.bottis.co.id/dataBSC',

//   dataBridging        : /*'http://192.168.0.143:9797', //*/ 'https://hris.bottis.co.id/dataBridging',

//   // dataBNI          : 'http://192.168.0.212:9933',

//   page                : 1,
//   rows                : 10,
//   headerToken         : 'x-auth-token',
//   menuDinamic         : true,
//   idleTimeOut         : 1440, // menit
//   menuStatic          :  [{
//           "label": "Dashboard",
//           "icon": "fa fa-fw fa-chevron-right",
//           "routerLink": ["/"]
//       }],
//   /// TIGA INI JGN PERNAH DIUBAH OKE.. HATURNUHUN
//   resourceFile        : /*'http://'+ window.location.hostname + ':8797', //*/'https://hris.bottis.co.id/authInfo',
//   resourceReport      : /*'http://'+ window.location.hostname + ':8797', //*/'https://hris.bottis.co.id/authInfo',
//   socketIO            : /*'http://192.168.0.143:2222'  //*/'https://hris.bottis.co.id'
// }

// export default vlocal;

// var vlocal = {
//   apiBackend: "",
//   dataMaster:
//     /*'http://'+ window.location.hostname + ':8292', //*/ "https://hris.rsgrandmed.com/dataMaster",
//   dataMasterNew:
//     /*'http://'+ window.location.hostname + ':8292', //*/ "https://hris.rsgrandmed.com/dataMaster",

//   report:
//     /*'http://'+ window.location.hostname + ':8797', //*/ "https://hris.rsgrandmed.com/authInfo",
//   smartReport:
//     /*'http://'+ window.location.hostname + ':8797', //*/ "https://hris.rsgrandmed.com/smartReport",
//   authLogin:
//     /*'http://'+ window.location.hostname + ':8797', //*/ "https://hris.rsgrandmed.com/authInfo",

//   dataHr1Mod1:
//     /*'http://'+ window.location.hostname + ':8393', //*/ "https://hris.rsgrandmed.com/dataHr1Mod1",
//   dataHr1Mod2:
//     /*'http://'+ window.location.hostname + ':8393', //*/ "https://hris.rsgrandmed.com/dataHr1Mod1",
//   dataHr2Mod1:
//     /*'http://'+ window.location.hostname + ':8393', //*/ "https://hris.rsgrandmed.com/dataHr1Mod1",
//   dataHr2Mod2:
//     /*'http://'+ window.location.hostname + ':8393', //*/ "https://hris.rsgrandmed.com/dataHr1Mod1",

//   dataHr2Mod3: "http://" + window.location.hostname + ":9696", //*/'https://hris.rsgrandmed.com/dataHr2Mod3',
//   dataBSC:
//     /*'http://192.168.0.143:9898', //*/ "https://hris.rsgrandmed.com/dataBSC",

//   dataBridging:
//     /*'http://192.168.0.143:9797', //*/ "https://hris.rsgrandmed.com/dataBridging",

//   // dataBNI          : 'http://192.168.0.212:9933',

//   page: 1,
//   rows: 10,
//   headerToken: "x-auth-token",
//   menuDinamic: true,
//   idleTimeOut: 1440, // menit
//   menuStatic: [
//     {
//       label: "Dashboard",
//       icon: "fa fa-fw fa-chevron-right",
//       routerLink: ["/"],
//     },
//   ],
//   /// TIGA INI JGN PERNAH DIUBAH OKE.. HATURNUHUN
//   resourceFile:
//     /*'http://'+ window.location.hostname + ':8797', //*/ "https://hris.rsgrandmed.com/authInfo",
//   resourceReport:
//     /*'http://'+ window.location.hostname + ':8797', //*/ "https://hris.rsgrandmed.com/authInfo",
//   socketIO: /*'http://192.168.0.143:2222'  //*/ "https://hris.rsgrandmed.com",
// };

// export default vlocal;

var vlocal = {
  apiBackend: "",
  dataMaster:
    /*'http://'+ window.location.hostname + ':8292', //*/ "https://hris.bottis.co.id/dataMaster",
  dataMasterNew:
    /*'http://'+ window.location.hostname + ':8292', //*/ "https://hris.bottis.co.id/dataMaster",

  report:
    /*'http://'+ window.location.hostname + ':8797', //*/ "https://hris.bottis.co.id/authInfo",
  smartReport:
    /*'http://'+ window.location.hostname + ':8797', //*/ "https://hris.bottis.co.id/smartReport",
  authLogin:
    /*'http://'+ window.location.hostname + ':8797', //*/ "https://hris.bottis.co.id/authInfo",

  dataHr1Mod1:
    /*'http://'+ window.location.hostname + ':8393', //*/ "https://hris.bottis.co.id/dataHr1Mod1",
  dataHr1Mod2:
    /*'http://'+ window.location.hostname + ':8393', //*/ "https://hris.bottis.co.id/dataHr1Mod1",
  dataHr2Mod1:
    /*'http://'+ window.location.hostname + ':8393', //*/ "https://hris.bottis.co.id/dataHr1Mod1",
  dataHr2Mod2:
    /*'http://'+ window.location.hostname + ':8393', //*/ "https://hris.bottis.co.id/dataHr1Mod1",

  dataHr2Mod3: /*"http://" + window.location.hostname + ":9696", /*/'https://hris.bottis.co.id/dataHr2Mod3',
  dataBSC:
    /*'http://192.168.0.143:9898', //*/ "https://hris.bottis.co.id/dataBSC",

  dataBridging:
    /*'http://192.168.0.143:9797', //*/ "https://hris.bottis.co.id/dataBridging",

  // dataBNI          : 'http://192.168.0.212:9933',

  page: 1,
  rows: 10,
  headerToken: "x-auth-token",
  menuDinamic: true,
  idleTimeOut: 1440, // menit
  menuStatic: [
    {
      label: "Dashboard",
      icon: "fa fa-fw fa-chevron-right",
      routerLink: ["/"],
    },
  ],
  /// TIGA INI JGN PERNAH DIUBAH OKE.. HATURNUHUN
  resourceFile:
    /*'http://192.168.0.102:9797', //*/ "https://hris.bottis.co.id/authInfo",
  resourceReport:
    /*'http://192.168.0.102:9797', //*/ "https://hris.bottis.co.id/authInfo",
  socketIO:
    /*'http://192.168.0.143:2222'  //*/ "https://hris.bottis.co.id",
};

export default vlocal;
