import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService, InfoService } from '../../global';

@Component({
  selector: 'app-redirect-menu-lembur-on-call',
  templateUrl: './redirect-menu-lembur-on-call.component.html',
  styleUrls: ['./redirect-menu-lembur-on-call.component.css']
})
export class RedirectMenuLemburOnCallComponent implements OnInit {

  link = [
    'daftar-kirim-pengajuan-lembur-on-call',
    'daftar-pengajuan-lembur-on-call',
    'daftar-terima-pengajuan-lembur-on-call'
  ];

  constructor(
    private route: Router,
    private info: InfoService,
    private settingsService: SettingsService
  ) { }

  ngOnInit() {
    let urlList = this.settingsService.getCurrentURL();
    for (let i = 0; i < this.link.length; i++) {
      if (!(urlList['/' + this.link[i]] == undefined || urlList['/' + this.link[i]] == null || urlList['/' + this.link[i]] != 1)) {
        localStorage.setItem('lembur-on-call-menu', String(i));
        this.route.navigate([this.link[i]]);
        break;
      }
    }
  }

}
